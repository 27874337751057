// Vendors
import { useEffect, useState } from 'react';
// Context
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Event handlers
import { TransactionsDetailsHandlers } from 'containers/transactions/handlers/details/transactions-details.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardTransactionsDetailType } from 'types/card-transtions-details.type';
import { TransactionsDetailsHookType } from './types/transactions-details.hook.type';
import { TransactionsDetailsHookReturnType } from './types/transactions-details-return.hook.type';

const useTransactionsDetailsHook = ({
  id,
  open,
  resolverDetail,
  error: wrapperError = null,
}: TransactionsDetailsHookType): TransactionsDetailsHookReturnType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<TransactionsFetchErrorEnumeration | null>(wrapperError);
  const [details, setDetails] = useState<CardTransactionsDetailType | any>({});

  const { formatDate } = useCbIntl();

  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard;

  const { handleCloseTransactionsDetail, handleFetchCardTransactionsDetail } =
    TransactionsDetailsHandlers({
      cardId: cardId,
      transactionId: id,
      resolver: resolverDetail,
      setFetching,
      setError,
      setDetails,
      wrapperError,
    });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) {
      handleFetchCardTransactionsDetail();
    } else {
      handleCloseTransactionsDetail();
    }
  }, [open]);

  return {
    fetching,
    formatDate,
    error,
    details,
  };
};

export { useTransactionsDetailsHook };
