// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  TRANSACTION_EPP_STEP2_MESSAGE_DESCRIPTION_PROPS,
  TRANSACTION_EPP_STEP2_MESSAGE_PROPS,
} from './constants/transactions-epp-step2-message.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Hooks
import { useTransactionsEppStep2MessageHook } from './hooks/transactions-epp-step2-message.hook';
// Translations
import { DESCRIPTION } from './translations/transactions-epp-step2-message.translations';
// Utilities
import { transactionHasBeenNotAuthorized } from './utils/transactions-epp-step2-message.utils';

const TransactionsEppStep2MessageComponent = (): React.ReactElement | null => {
  const {
    currencyCode: currency,
    loanAmount,
    primaryAccountId: account,
    transactionStatusCode,
  } = useTransactionsEppStep2MessageHook();

  return transactionHasBeenNotAuthorized(transactionStatusCode) ? (
    <MessageComponent {...TRANSACTION_EPP_STEP2_MESSAGE_PROPS}>
      <ParagraphComponent {...TRANSACTION_EPP_STEP2_MESSAGE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent
          id={DESCRIPTION}
          values={{
            account,
            amount: (
              <FormattedNumber
                {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
                value={loanAmount}
              />
            ),
          }}
        />
      </ParagraphComponent>
    </MessageComponent>
  ) : null;
};

export { TransactionsEppStep2MessageComponent };
